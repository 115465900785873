function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}

export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }
  const nextValue = clearNumber(value);
  const final = `${nextValue.slice(0, 4)} ${nextValue.slice(
    4,
    8
  )} ${nextValue.slice(8, 12)} ${nextValue.slice(12, 19)}`;
  return final;
}
export function formatCreditCardExpiry(value) {
  const date = clearNumber(value);
  if (date.length >= 3) {
    return `${date.slice(0, 2)}/${date.slice(2, 4)}`;
  }
  return date;
}
export function formatSecurityCode(value) {
  const code = clearNumber(value);
  return code;
}

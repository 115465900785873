import React from "react";

function Footer() {
  return (
    <div className=" bg-dark py-5 px-10">
      <div className="max-w-7xl text-white mx-auto">
        <p className="text-center md:text-left">
          Swipemint © All Rights Reserved 2022
        </p>
      </div>
    </div>
  );
}

export default Footer;

import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import axios from "../axios";
import "./CardBuilder.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
// import emailjs from "@emailjs/browser";
function PaymentModal({ price, onChange, cardDetails, onPaymentSuccess }) {
  const stripe = useStripe();
  const elements = useElements();
  const [insure, setInsure] = useState(false);
  const [express, setExpress] = useState(false);
  const [total, setTotal] = useState(Number(price));
  const [tax, setTax] = useState(0);
  const [error, setError] = useState("");
  const [processing, setProcessing] = useState(false);
  const [userDetails, setUserDetail] = useState({
    name: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  });
  const changeHandler = (e) => {
    e.preventDefault();
    setUserDetail({ ...userDetails, [e.target.name]: e.target.value });
  };
  const cardElementOptions = {
    style: {
      base: {
        borderBottom: "2px solid black",
      },
    },
    hidePostalCode: true,
  };
  useEffect(() => {
    let t = (total / 100) * 5;
    let tax = t.toFixed(2);
    setTax(Number(tax));
  }, [total]);
  // const sendEmail = () => {
  //   emailjs
  //     .sendForm(
  //       "service_mfljhz9",
  //       "template_6phonoo",
  //       form.current,
  //       "user_jr3eHkIxWiguBbafNRZUE"
  //     )
  //     .then(
  //       (result) => {
  //         console.log(result.text);
  //       },
  //       (error) => {
  //         console.log(error.text);
  //       }
  //     );
  // };
  const submitHandler = async (e) => {
    e.preventDefault();
    // sendEmail();
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardElement);

    const { error } = await stripe.createToken(cardElement);

    if (!error) {
      setProcessing(true);
      setError("");

      try {
        const { clientSecret } = await axios
          .post("/create-payment-intent", {
            currency: "usd",
            amount: (total + tax) * 100,
            cardDetails,
            userDetails,
          })
          .then((r) => r.data);

        const { paymentIntent } = await stripe.confirmCardPayment(
          String(clientSecret),
          {
            payment_method: {
              card: elements.getElement(CardElement),
            },
          }
        );
        console.log("Payment " + paymentIntent.status);

        setProcessing(false);
        onChange(false);
        onPaymentSuccess(true);
      } catch (e) {
        console.log(e);
      }
    } else {
      setError(error.message);
    }
  };
  return (
    <div className="modal fixed w-full h-full top-0 left-0 flex items-center justify-center z-20">
      <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
      <div className="modal-container  bg-white w-11/12 md:w-3/4 max-w-4xl mx-auto rounded shadow-lg z-50 overflow-y-auto relative">
        {/* <div className="modal-close  absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-20"></div> */}

        <div className="modal-content py-4 text-left px-6">
          <div className="flex justify-between items-center pb-3">
            <p className="text-2xl font-bold text-gray-900">Checkout</p>
            <div
              onClick={() => onChange(false)}
              className="modal-close cursor-pointer z-50"
            >
              <svg
                className="fill-current text-black"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
              </svg>
            </div>
          </div>
          <div className="flex justify-between p-5 flex-col md:flex-row gap-6 md:gap-0 items-end md:items-start">
            <div className="flex w-full ">
              <form
                onSubmit={submitHandler}
                action="#"
                id="form"
                className=" md:pr-10 gap-4 md:gap-8 flex flex-col w-full"
              >
                <div className="flex flex-col md:flex-row">
                  <input
                    required
                    name="name"
                    onChange={changeHandler}
                    type="text"
                    placeholder="Name"
                    className="border-0 border-b-2 border-gray-300 focus:outline-0 w-full"
                  />
                  <input
                    required
                    name="email"
                    onChange={changeHandler}
                    type="email"
                    placeholder="Email"
                    className="border-0 border-b-2 border-gray-300 md:ml-4 mt-4 md:mt-0 focus:outline-0 w-full"
                  />
                </div>

                <input
                  required
                  type="text"
                  name="address"
                  onChange={changeHandler}
                  placeholder="Address"
                  className="border-0 border-b-2 border-gray-300 focus:outline-0 w-full"
                />
                <div className="flex ">
                  <input
                    name="city"
                    required
                    type="text"
                    onChange={changeHandler}
                    placeholder="City"
                    className="border-0 border-b-2 border-gray-300 focus:outline-0 w-full"
                  />
                  <input
                    required
                    name="state"
                    type="text"
                    onChange={changeHandler}
                    placeholder="State/ Province"
                    className="border-0 border-b-2 border-gray-300 ml-4 focus:outline-0 w-full"
                  />
                </div>
                <div className="flex ">
                  <input
                    required
                    name="zip"
                    onChange={changeHandler}
                    type="text"
                    placeholder="Zip/ Postal Code"
                    className="border-0 border-b-2 border-gray-300 focus:outline-0 w-full"
                  />
                  <input
                    required
                    name="country"
                    type="text"
                    onChange={changeHandler}
                    placeholder="Country"
                    className="border-0 border-b-2 border-gray-300 ml-4 focus:outline-0 w-full"
                  />
                  {/* <input type="hidden" name="card" value={card} />
                  <input type="hidden" name="chip" value={chip} /> */}
                </div>
                {/* <div className="flex flex-col md:flex-row gap-4 md:gap-0"> */}
                <CardElement options={cardElementOptions} />
                {error && <div className=" text-red-500 text-sm">{error}</div>}
                {/* <div className="flex">
                    <input
                      required
                      type="text"
                      placeholder="Card Number"
                      className="border-0 border-b-2 border-gray-300 focus:outline-0  w-full"
                    />
                  </div>
                  <div className="flex">
                    <select
                      required
                      className="border-0 border-b-2 border-gray-300 focus:outline-0 w-full md:ml-4 text-gray-400"
                    >
                      <option value="" selected disabled>
                        MM
                      </option>
                      {months.map((m) => (
                        <option key={m.label} value={m.value}>
                          {m.label}
                        </option>
                      ))}
                    </select>
                    <select
                      required
                      className="border-0 border-b-2 border-gray-300 focus:outline-0 w-full ml-4 text-gray-400"
                    >
                      <option value="" selected disabled>
                        YY
                      </option>
                      {years.map((y) => (
                        <option value={y.value}>{y.label}</option>
                      ))}
                    </select>
                    <input
                      required
                      type="text"
                      placeholder="CVV"
                      className="border-0 border-b-2 border-gray-300 ml-4 focus:outline-0 w-full"
                    />
                  </div> */}
                {/* </div> */}
                <button
                  type="submit"
                  className="modal-close px-4 bg-indigo-500 p-3 rounded-md text-white hover:bg-indigo-400"
                >
                  <span className="flex justify-center items-center">
                    {processing && (
                      <div className="relative">
                        <svg
                          className=" h-5 w-5 mr-3 rounded-full border-2 absolute"
                          viewBox="0 0 24 24"
                        ></svg>
                        <svg
                          className="animate-spin h-5 w-5 mr-3 rounded-full border-t-2 border-indigo-500 "
                          viewBox="0 0 24 24"
                        ></svg>
                      </div>
                    )}
                    Pay Now
                  </span>
                </button>
              </form>
            </div>
            <div className="flex flex-col items-start self-center -order-1 md:order-1">
              <div className="flex">
                <input
                  type="text"
                  name="coupon"
                  placeholder="Discount Code"
                  className=" border-2 border-gray-300 p-2 rounded-l-md border-r-0 focus:outline-indigo-300  "
                />
                <button className=" text-white bg-indigo-500 p-2 rounded-r-md border-2 border-indigo-500 active:bg-indigo-400">
                  Apply
                </button>
              </div>
              <div className="flex gap-5 mt-1 text-gray-500 py-4">
                <div className="flex flex-col gap-1 text-right">
                  <h5 className=" font-semibold text-lg">Card</h5>

                  <div className="flex items-center justify-between w-full ">
                    <p className="font-semibold mr-3 text-sm">
                      Lifetime Insurance
                    </p>
                    <label
                      htmlFor="insurance"
                      className="flex items-center cursor-pointer"
                    >
                      <div className="relative">
                        <input
                          type="checkbox"
                          id="insurance"
                          className="sr-only "
                          onChange={(e) => {
                            if (e.target.checked) {
                              setInsure(true);
                              setTotal(total + 99);
                            } else {
                              setInsure(false);
                              setTotal(total - 99);
                            }
                          }}
                        />

                        <div className="block dot_bg bg-gray-500 w-8 h-5 rounded-full"></div>

                        <div className="dot absolute left-1 top-1 bg-white w-3 h-3 rounded-full transition"></div>
                      </div>
                    </label>
                  </div>
                  <div className="flex items-center justify-between w-full ">
                    <p className="font-semibold mr-2 text-sm">
                      Express Shipping
                    </p>
                    <label
                      htmlFor="shipping"
                      className="flex items-center cursor-pointer"
                    >
                      <div className="relative">
                        <input
                          onChange={(e) => {
                            if (e.target.checked) {
                              setExpress(true);
                              setTotal(total + 69);
                            } else {
                              setExpress(false);
                              setTotal(total - 69);
                            }
                          }}
                          type="checkbox"
                          id="shipping"
                          className="sr-only"
                        />

                        <div className="block dot_bg bg-gray-500 w-8 h-5 rounded-full"></div>

                        <div className="dot absolute left-1 top-1 bg-white w-3 h-3 rounded-full transition"></div>
                      </div>
                    </label>
                  </div>

                  <p className="font-semibold">Tax(5%)</p>
                  <p className="font-semibold">Total</p>
                </div>
                <div className="flex gap-1 flex-col w-16">
                  <h5 className="font-semibold text-lg">${price}</h5>
                  <p className="font-semibold text-sm">
                    +{insure ? "$99" : "$0.00"}
                  </p>
                  <p className="font-semibold text-sm">
                    +{express ? "$69" : "$0.00"}
                  </p>
                  <p className="font-semibold">+${tax}</p>
                  <p className="font-semibold">+${total + tax}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-100  pt-2  ml-auto"></div>
        </div>
      </div>
    </div>
  );
}

export default PaymentModal;

import React from "react";
import "./CardBuilder.css";
import goldenCard_back from "../img/gold_back.png";
import silverCard_back from "../img/silver_back.png";
import goldenCard from "../img/gold_sChip.png";
import goldenCardL from "../img/gold_lChip.png";
import silverCard from "../img/silver_sChip.png";
import silverCardL from "../img/silver_lChip.png";
function CardImgs({ card, cardCode, cardExpiry, cardName, chip, cardNumber }) {
  return (
    <div className="md:w-2/3 mx-0 sm:mx-3 ">
      <div className="card relative">
        {card === "golden" ? (
          <img
            className="rounded-xl  mb-5  "
            src={chip === "small" ? goldenCard : goldenCardL}
            alt="golden card front"
          />
        ) : (
          <img
            className="rounded-xl  mb-5  "
            src={chip === "small" ? silverCard : silverCardL}
            alt="sliver card front"
          />
        )}
        <p className="card__name absolute font-semibold ">{cardName}</p>
        <p className="card__number absolute font-semibold w-full">
          {cardNumber}
        </p>
        <p className="card__expiry absolute  font-semibold ">{cardExpiry}</p>
      </div>
      <div className="card relative">
        <img
          className="rounded-xl "
          src={card === "golden" ? goldenCard_back : silverCard_back}
          alt="golden card back"
        />
        <p className="card__code absolute font-semibold bottom-1/2 right-56">
          {cardCode}
        </p>
      </div>
    </div>
  );
}

export default CardImgs;

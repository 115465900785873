import React from "react";

function CardButtons(props) {
  const Cards = props.Cards;
  return (
    <div className="">
      <p className="font-semibold text-white ">Card</p>
      <div className="w-100  rounded-md bg-white p-1 flex flex-col xs:flex-row md:gap-1 ">
        {Cards.map((card) => (
          <div
            onClick={(e) => props.onChange(card.title)}
            key={card.id}
            className={` ${card.bg_url} ${
              props.card === card.title
                ? "border-indigo-500 border-4"
                : " hover:scale-101 active:scale-95"
            } scale-95 h-24 bg-cover w-full rounded-md   transition duration-100 text-white font-semibold`}
          >
            <button className="bg-gradient-to-t from-gray-700 h-full w-full p-2 rounded-md font-semibold">
              <div className="absolute bottom-2 select-none capitalize">
                {card.title}
              </div>
              <div className="absolute bottom-2 right-2 select-none">
                ${card.price}
              </div>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CardButtons;

import React, { useEffect, useState } from "react";
import CardButtons from "./CardButtons";
import PaymentSuccess from "./PaymentSuccess";
import CardImgs from "./CardImgs";
import "./CardBuilder.css";
import {
  formatCreditCardNumber,
  formatCreditCardExpiry,
  formatSecurityCode,
} from "../utils";
import PaymentModal from "./PaymentModal";
import Header from "./Header";
import Footer from "./Footer";
const cardsInfo = [
  {
    id: 1,
    bg_url: "bg-[url('img/gold_sChip.png')]",
    title: "golden",
    price: 200,
    rating: 10,
  },
  {
    id: 2,
    bg_url: " bg-[url('img/silver_sChip.png')]",
    title: "silver",
    price: 180,
    rating: 5,
  },
];

function CardBuilder() {
  const [cardName, setCardName] = useState("Name Here");
  const [cardNumber, setCardNumber] = useState("1234 1234 1234 1234");
  const [cardExpiry, setCardExpiry] = useState("01/22");
  const [cardCode, setCardCode] = useState("123");
  const [chip] = useState("small");
  const [card, SetCard] = useState("golden");
  const [price, setPrice] = useState("");
  const [checkout, setCheckout] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  useEffect(() => {
    cardsInfo.map((el) => card === el.title && setPrice(el.price));
  });

  return (
    <>
      <Header />
      <div className=" body-background  py-10 px-5 md:px-3">
        {paymentSuccess && <PaymentSuccess />}
        {checkout && (
          <PaymentModal
            cardDetails={{
              cardName,
              cardNumber,
              cardExpiry,
              cardCode,
              chip,
              card,
            }}
            price={`${price}`}
            onChange={(value) => setCheckout(value)}
            onPaymentSuccess={() => setPaymentSuccess(true)}
          />
        )}
        <div className="max-w-6xl mx-auto">
          <CardButtons
            Cards={cardsInfo}
            card={card}
            onChange={(value) => SetCard(value)}
          />
          <div className="flex py-7 gap-5 flex-col md:flex-row ">
            <CardImgs
              card={card}
              chip={chip}
              cardName={cardName}
              cardExpiry={cardExpiry}
              cardCode={cardCode}
              cardNumber={cardNumber}
            />
            <div className="md:w-1/3 md:ml-3 mt-3 md:mt-0">
              {/* <h3 className="text-white font-semibold">Replica Rating</h3>
              {cardsInfo.map((info) =>
                card === info.title ? (
                  <div key={info.id} className="flex justify-between flex-wrap">
                    <p className=" text-2xl text-white font-bold">
                      {info.rating}/10
                    </p>
                    <p className="text-md md:text-xl">
                      {[...Array(info.rating)].map((el, index) => "⭐")}
                    </p>
                  </div>
                ) : (
                  ""
                )
              )} */}
              {/* 
              <h3 className="text-white font-semibold mt-3 mb-1">Chip Type</h3>
              <div className="flex bg-gray-200 rounded-md ">
                <button
                  onClick={() => setChip("small")}
                  className={` w-1/2 m-1 border-2 rounded-md py-2 shadow-sm font-semibold ${
                    chip === "small"
                      ? " body-background text-white"
                      : "bg-white text-gray-800"
                  } border-gray-300 active:scale-95 transition duration-75`}
                >
                  Small
                </button>
                <button
                  onClick={() => setChip("big")}
                  className={` w-1/2 m-1 border-2 rounded-md py-2 shadow-sm font-semibold ${
                    chip === "big"
                      ? " body-background text-white"
                      : "bg-white text-gray-800"
                  } border-gray-300 active:scale-95 transition duration-75`}
                >
                  Big
                </button>
              </div> */}
              <h3 className="text-white font-semibold mt-3 mb-1">
                Card Details
              </h3>
              <form
                id="payment-form"
                action=""
                className="bg-gray-200 rounded-md p-2 shadow-sm"
              >
                <label
                  htmlFor="cName"
                  className=" block text-sm font-semibold text-gray-800"
                >
                  Card Name
                </label>
                <input
                  className="p-3 rounded-md w-full my-1 focus:outline-0  placeholder:text-sm mb-2 shadow-sm"
                  type="text"
                  placeholder="Card Name"
                  onChange={(e) => {
                    setCardName(e.target.value);
                  }}
                  name="cName"
                  value={cardName}
                  id="cName"
                  required
                />
                <label
                  htmlFor="cNumber"
                  className=" block text-sm font-semibold text-gray-800"
                >
                  Card Number
                </label>
                <input
                  className="p-3 rounded-md w-full my-1 focus:outline-0 placeholder:text-sm mb-2 shadow-sm"
                  type="tel"
                  maxLength="19"
                  pattern="[\d |]{16,19}"
                  placeholder="Card Number"
                  onChange={(e) => {
                    e.target.value = formatCreditCardNumber(e.target.value);
                    setCardNumber(e.target.value);
                  }}
                  name="cNumber"
                  id="cNumber"
                  value={cardNumber}
                  required
                />
                <label
                  htmlFor="eDate"
                  className=" block text-sm font-semibold text-gray-800"
                >
                  Expiry Date
                </label>
                <input
                  className="p-3 rounded-md w-full my-1 focus:outline-0 placeholder:text-sm mb-2 shadow-sm"
                  type="tel"
                  pattern="\d\d/\d\d"
                  placeholder="Expiry Date"
                  value={cardExpiry}
                  onChange={(e) => {
                    e.target.value = formatCreditCardExpiry(e.target.value);
                    setCardExpiry(e.target.value);
                  }}
                  name="eDate"
                  id="eDate"
                  required
                />
                <label
                  htmlFor="sCode"
                  className=" block text-sm font-semibold text-gray-800"
                >
                  Security Code
                </label>
                <input
                  className="p-3 rounded-md w-full my-1 focus:outline-0 placeholder:text-sm shadow-sm"
                  type="tel"
                  maxLength="5"
                  placeholder="Security Code"
                  onChange={(e) => {
                    e.target.value = formatSecurityCode(e.target.value);
                    setCardCode(e.target.value);
                  }}
                  value={cardCode}
                  name="sCode"
                  id="sCode"
                  required
                />
              </form>
              <button
                onClick={() => setCheckout(true)}
                className=" button-text-color bg-white my-5 p-3 w-full rounded-md font-semibold active:scale-95 transition duration-75"
              >
                Checkout(${price})
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CardBuilder;

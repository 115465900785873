import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CardBuilder from "./components/CardBuilder";

(async () => {
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );
  ReactDOM.render(
    <React.StrictMode>
      <Elements stripe={stripePromise}>
        <CardBuilder />
      </Elements>
    </React.StrictMode>,
    document.getElementById("root")
  );
})();

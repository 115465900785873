import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import logo from "../img/logo.png";
const solutions = [
  {
    name: "Home",
    href: "#",
  },
  {
    name: "Order",
    href: "#",
  },
  {
    name: "FAQ",
    href: "#",
  },
  {
    name: "Instagram",
    href: "#",
  },
];

export default function Header() {
  return (
    <Popover className="relative bg-white ">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 border-t-4 border-gold">
        <div className="flex justify-between border-b-2 border-gray-100  md:justify-start md:space-x-10 py-8 md:py-0">
          <div className="flex justify-start lg:w-0 lg:flex-1 relative  items-center">
            <img className="h-8 w-auto sm:h-10 " src={logo} alt="logo" />
            <div className="absolute border-l-[1px] border-gray-500 border-opacity-20 left-[240px] h-[100%] hidden lg:block">
              <span></span>
            </div>
          </div>

          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 ">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-10 w-10 text-dark" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group
            as="nav"
            className="hidden md:flex space-x-10 py-8 md:py-12"
          >
            {solutions.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="flex items-center hover:text-gold  border-b-gold border-0 hover:border-b-2 rounded-none transition duration-250 "
              >
                <span
                  className={` text-base font-extrabold   ${
                    item.name === "Home" ? "text-gold" : "text-gray-800"
                  } `}
                >
                  {item.name}
                </span>
              </a>
            ))}
          </Popover.Group>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-500  transition-transform ease-out"
        enterFrom="-translate-x-1/2"
        enterTo="translate-x-0"
        leave="duration-200  transition-transform ease-in"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-1/2"
      >
        <Popover.Panel
          focus
          className="  absolute top-0 inset-x-0 border-t-4 border-gold transition transform origin-top-right md:hidden z-30"
        >
          <div className="  ring-1 ring-black ring-opacity-5 bg-gold divide-y-2 divide-gray-50">
            <div className="h-[100vh]">
              <div className="flex items-center justify-between bg-white p-4 py-8">
                <div className="">
                  <img className="h-8 w-auto" src={logo} alt="logo" />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none ">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-8 w-8 text-dark" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className=" p-4">
                <nav className="grid gap-y-8">
                  {solutions.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className={`-m-4 p-3 flex items-center  border-b-[1px] border-white  hover:bg-dark active:bg-dark `}
                    >
                      <span className="ml-3 text-2xl font-bold text-white">
                        {item.name}
                      </span>
                    </a>
                  ))}
                </nav>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
